import React from 'react'
import TopBar from './TopBar'
import Footer from './Footer'
import OuterContainer from './OuterContainer'
import styled from '@emotion/styled'
import system from '../../design/theme'

const FourOhFour = () => {
  return (
    <OuterContainer>
      <TopBar />
      <Container>
        <h1>
        Work in progress - check back later :)
        </h1>
        <p>Hit the logo up top to navigate back to the home page.</p>
      </Container>
      <Footer/>
    </OuterContainer>
  )
}

export default FourOhFour

const Container = styled('div')`
  margin: 5rem 5rem 0;
  min-height: 86.5vh;
  padding: 0 4rem;
  background: ${system.color.secondary};
  box-shadow: ${system.shadows.otherLight};
  position: relative;
  text-align: center; 

  h1 {
    margin-top: 105px;
    font-size: 100px;
    background: linear-gradient(65deg, #EF476F, #118AB2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: ${system.fontSizing.xl};
    color: ${system.color.bodytext};
    margin: 100px 0;
    
  }
`
