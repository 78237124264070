import React from 'react'
import styled from '@emotion/styled'
import system from '../../design/theme'

const Footer = () => {
  return (
    <StyledFooter>
      <p id="copy">Congrats, you got to the bottom of the page &mdash; &copy; 2023 Unfuck Your Resume</p>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  background: ${system.color.bodytext};
  color: ${system.color.white}
`
