import React, { Component } from 'react'
import { Global, css } from '@emotion/core'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import ScrollToTop from './components/common/ScrollToTop'
import Home from './views/Home'
// import About from './views/About'
// import Apply from './views/Apply'
// import Ask from './views/Ask'
import FourOhFour from './components/common/FourOhFour'
import system from './design/theme'
import ReactGA from 'react-ga'
import './reset.css'

// google analytics

ReactGA.initialize('UA-133547587-1', {
  debug: false, // sends feedback to the console
  titleCase: false,
  userId: 133547587,
  siteSpeedSampleRate: 100 // rate at which data is sent, default is 1
})
ReactGA.pageview(`${window.location.pathname}`)

class App extends Component {
  render() {

    return (
      <React.Fragment>
        <Global
          styles={css`
            html {
              font-size: 62.5%;
              font-family: 'Montserrat', sans-serif;
              ::-webkit-scrollbar {
                width: 4px;
                background-color: rgba(0, 0, 0, 0.041);
              }
              ::-webkit-scrollbar-thumb {
                background: ${system.color.primary};
                width: 4px;
                border-radius: 50px;
              }
            }

            body {
              height: 100vh;
              &.no-scroll {
                overflow: hidden;
              }
            }

            #root {
              height: 100%;
              width: 100%;
            }

            * {
              box-sizing: border-box;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            label {
              font-family: 'Lato', serif;
            }

            a,
            input,
            button {
              text-decoration: none;
              font-family: 'Montserrat', sans-serif;
              outline: none;
            }

            .demo-bold {
              font-family: 'Lato', sans-serif;
              font-weight: bold;
              color: ${system.color.primary};
            }

            .demo-video {
              margin-top: 20px;
              width: 100%;
              height: auto;
            }
          `}
        />
        <Router>
          <ScrollToTop>
            <Switch>
              <Route
                exact
                path='/'
                component={Home}
              />
              <Route path="*" exact={true} component={FourOhFour} />
            </Switch>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    )
  }
}

export default App