import React from 'react'
import TopBar from '../components/common/TopBar'
import Footer from '../components/common/Footer'
import styled from '@emotion/styled'
import system from '../design/theme'
import Button from '../components/common/Button'
import LinkItem from '../components/common/LinkItem'
import OuterContainer from '../components/common/OuterContainer'
import Fade from 'react-reveal/Fade'
import poo from '../assets/poo-emoji.jpg'
import rahul from '../assets/rahul.jpg'

const Home = () => {
  const width = window.innerWidth
  return (
    <OuterContainer>
      <TopBar />
      <Container>
        <section id="hero">
          <Fade bottom duration={width < 600 ? 0 : 750} >
            <img id="hero-img" src={poo} alt="A clay sculpture of the poop emoji"/>
          </Fade>
          <Fade top duration={width < 600 ? 0 : 750}>
            <div className="wrapper">
              <h1>Hey, I've got some bad news.</h1>
              <p>
              Your resume is shit. <span>A bit like my friend to your right.</span>
                <br></br>
                <br></br>
              And if you use a font like my logo, your resume isn't special & unique. It's not even getting seen by a recruiter. 
                <br></br>
                <br></br>
              I bet you're tired of sending out shitty resumes and getting no response. Do you want to land that job you fucking deserve? Then let's unfuck your resume together.</p>
              <Button type="text" className="landing">
                <LinkItem to="/">Unfuck My Resume</LinkItem>
              </Button>
            </div>
          </Fade>
        </section>

        <section id="about">
          <Fade bottom duration={width < 600 ? 0 : 750} >
            <img id="headshot" src={rahul} alt="A clay sculpture of the poop emoji"/>
          </Fade>
          <Fade top duration={width < 600 ? 0 : 750}>
            <div className="wrapper">
              <h1>I'm Rahul Desai and I've been in your shoes.</h1>
              <p>I know what it's like to send out countless resumes and hear nothing but crickets. 
                <br></br>
                <br></br>
                But I've also been on the other side of the table, as a hiring manager, college admissions interviewer, and job search coach. I've done a metric fuckton of resume reviews (seriously, over 1000 of them) and served on hundreds of hiring panels.                  
                <br></br>
                <br></br>
                I've seen (and sometimes helped) dumber, less qualified people than you snag their dream job. I know how to unfuck a resume and turn it into a goddamn work of art. I'll make sure your skills and experience shine like a diamond in a sea of turds. And I'll do it all with a heaping dose of profanity and no bullshit.
                <br></br>
                <br></br>
                So what are you waiting for? Let's unfuck your resume and get you that goddamn job you dream of. Contact me today to get started.
              </p>
              <Button type="text" className="landing">
                <LinkItem to="/">Unfuck My Resume</LinkItem>
              </Button>
            </div>
          </Fade>
        </section>

        <section id="features">
          <h1>I'm full-service & I don't peddle bullshit.</h1>
          <p>
            I'll help you with whatever it takes to win that job offer (and then not shit the bed after you start the job).
          </p>
          <div className="cards">
            <Fade top duration={width < 600 ? 0 : 750}>
              <div className="card">
                <h2>
                Async Review 
                  <br></br>
                  <span>
                  $99 per review
                  </span>
                </h2>
                <p>
                I'll go through your resume, cover letter, or LinkedIn asynchronously:<br/><br/> You send me your materials to review and I'll send you a video recording with my feedback. <br/><br/> If you need quick, high-level feedback, this is the best offering for you.
                </p>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
              <div className="card">
                <h2>
                  Coaching Call
                  <br></br>
                  <span>$249 per 45 minutes</span>
                </h2>
                <p>
                If you need more detailed guidance, we can get on the phone. <br></br><br></br> We can rewrite your documents, do interview practice, and/or discuss business problems.<br/><br/>I have 4 main offerings:
                  <ul>
                    <li>
                      Full Resume Rewrite
                    </li>
                    <li>
                      Full Cover Letter Rewrite
                    </li>
                    <li>
                      Interview Prep 
                    </li>
                    <li>
                      Career Coaching
                    </li>
                  </ul>
                </p>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
              <div className="card">
                <h2>
                  Salary Negotiation
                  <br></br>
                  <span>Contact for Pricing</span>
                </h2>
                <p>
                One of the things I enjoy most is helping people with salary negotiation: I tripled my own income within 6 years.<br/><br/> I have helped people earns tens of thousands more than their initial offer letters. <br/><br/> We'll gameplan exactly what you need to say to earn much more cash, stock, and/or benefits.
                </p>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
              <div className="card">
                <h2>
                  Business Consulting
                  <br></br>
                  <span>Contact for Pricing</span>
                </h2>
                <p>
                If you're stuck on a particularly thorny business problem, you can hire me to consult for your business.<br/><br/> While my expertise is in strategy and operations, I have worked across and overseen all corporate functions, including marketing, sales, compliance, IT, HR, and finance / accounting.
                </p>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
            </Fade>
          </div>
        </section>

        <section id="social-proof">
          <h1>But don't take my word for it.</h1>
          <p>
            I've unfucked all sorts of things: resumes, cover letters, interview prep, salary negotiation, and more. Here's what my clients have to say.
          </p>
          <div className="cards">
            <Fade top duration={width < 600 ? 0 : 750}>
              <div className="card large">
                <div className="stripe" />
                <p>
                Just wanted to say thank you again for the absolute wealth of knowledge you have shared with me. My role and scope has evolved in such a way that I am owning massive projects and have autonomy. I wouldn't be in a position to confidently say yes to these things if it wasn't for you. I am advocating for myself and building cool shit. We are launching a whole new product that I am owning. I can't thank you enough.</p>
                <h2>
                  &ndash; Sheli D.
                </h2>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
              <div className="card large">
                <div className="stripe" />
                <p>
                After getting to several final round interviews but not getting an offer, my confidence was a bit shaken. Rahul helped me make a game plan for my next set of interviews, practicing answers with me and giving advice not just on content but tone and confidence. With his help, I landed my dream job (being appointed CEO!) through that next interview.</p>
                <h2>
                  &ndash; Katherine B.
                </h2>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
              <div className="card large">
                <div className="stripe" />
                <p>
                Your advice was great and, again, thank you so much for helping me. It does look a lot better and shows my accomplishments more. That resume the other service sent me was way too cluttered and I hadn't seen one formatted like that in years <span role="img" aria-label='emoji'>😆</span> I'm glad I knew something was wrong and had you to ask!</p>                
                <h2>
                  &ndash; Kaitlin C.
                </h2>
                <Button type="text" className="landing">
                  <LinkItem to="/">TEST</LinkItem>
                </Button>
              </div>
            </Fade>
            <Fade top duration={width < 600 ? 0 : 750}>
              <div className="card mid">
                <div className="stripe" />
                <p>
                Just want to say, Rahul Desai's format for writing cover letters has me super excited to start using it! Rahul, your style of explanation is straightforward and concise, the example was great, and this was eye-opening.</p>
                <h2>
                  &ndash; Helena W.
                </h2>
              </div>
              <div className="card mid">
                <div className="stripe" />
                <p>
                Rahul, thank you so much for your wonderful feedback on the Cover Letter and Resume. I agree with everything you've shared. It's been a while since I dusted off my resume, so I'm so appreciative of the feedback.</p>
                <h2>
                  &ndash; Pamela G.
                </h2>
              </div>
              <div className="card mid">
                <div className="stripe" />
                <p>
                I haven't had to put together a resume in years so I'm slightly out of touch and Rahul provided some really great feedback and took an in-depth look. I submitted my new resume out and got an interview lined up off it in less than 24 hours! BIG THANKS!
                </p>
                <h2>
                  &ndash; Abby O.
                </h2>
              </div>
            </Fade>
            <Fade top duration={width < 600 ? 0 : 750}>
              <div className="card">
                <div className="stripe" />
                <p>
                This is amazing. I can't thank you enough, Rahul! Thanks so much for the time and the help!!</p>
                <h2>
                  &ndash; Mitch R.
                </h2>
              </div>
              <div className="card" >
                <div className="stripe" />
                <p>
                Hi Rahul, Thanks so much for taking the time to review and provide feedback. I'll be making the changes you suggested. Much appreciated <span role="img" aria-label='emoji'>😀</span>
                </p>
                <h2>
                  &ndash; Miranda R.
                </h2>
              </div>
              <div className="card">
                <div className="stripe" />
                <p>
                UNBELIEVABLY helpful, thank you so much. Quite simple changes for tangible impact.
                </p>
                <h2>
                  &ndash; Izzy D.
                </h2>
              </div>
            </Fade>
            <Fade top duration={width < 600 ? 0 : 750}>
              <div className="card">
                <div className="stripe" />
                <p>
                Thanks Rahul, I really appreciate you taking the time to go through my resume and LinkedIn!
                </p>                
                <h2>
                  &ndash; Karen L.
                </h2>
              </div>
              <div className="card">
                <div className="stripe" />
                <p>
                this makes sense!! thank you so much - this is easily actionable feedback!</p>
                <h2>
                  &ndash; Stefania H.
                </h2>
              </div>
              <div className="card">
                <div className="stripe" />
                <p>
                Hey Rahul - thanks so much for the feedback. Taking it in and making some edits. Appreciate you!                
                </p>
                <h2>
                  &ndash; Alexa A.
                </h2>
              </div>
            </Fade>
            <Fade top duration={width < 600 ? 0 : 750}>
              <div className="card">
                <div className="stripe" />
                <p>
                Thanks, Rahul! I really appreciate your feedback <span role="img" aria-label='emoji'>🙂</span>            
                </p>                
                <h2>
                  &ndash; Sam D.
                </h2>
              </div>
              <div className="card">
                <div className="stripe" />
                <p>
                This advice is so tactical and helpful: thank you so much!</p>
                <h2>
                  &ndash; Danny A.
                </h2>
              </div>
              <div className="card">
                <div className="stripe" />
                <p>
                This is fantastic, thank you so much for taking the time!           
                </p>
                <h2>
                  &ndash; Dominic P.
                </h2>
              </div>
            </Fade>
          </div>
        </section>
      </Container>
      <Footer />
    </OuterContainer>
  )
}

export default Home

const Container = styled('div')`
  margin: 5rem 5rem 0;
  padding: 0 4rem;
  background: ${system.color.secondary};
  box-shadow: ${system.shadows.otherLight};
  position: relative;

  @media ${system.breakpoints[2]} {
    margin: 5rem 2rem 0;
    padding: 0 2rem;

    section {
      padding-bottom: 5rem;

      h1,
      p {
        padding-left: 0;
      }
    }
  }

  section {
    padding-bottom: 7rem;

    h1 {
      padding: 100px 0 0;
      font-size: ${system.fontSizing.xl};
      width: 50%;
      text-align: left;
      color: ${system.color.primary};
    }

    p {
      padding: ${system.spacing.bigPadding};
      color: ${system.color.bodytext};
      font-size: ${system.fontSizing.ml};
      width: 55%;
      margin-bottom: 30px;
      line-height: ${system.spacing.lineHeight};
    }

    @media ${system.breakpoints[2]} {
      h1 {
        width: 65%;
        margin-left: ${system.spacing.bigPadding};
      }
      p {
        width: 65%;
      }
      span {
        display: none;
      }
    }

    @media ${system.breakpoints[0]} {
      h1 {
        font-size: ${system.fontSizing.ml};
      }
      p {
        width: 100%;
        font-size: ${system.fontSizing.m};
        padding: 1rem ${system.spacing.bigPadding};
      }
    }
  }

  #hero {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10rem 0;
    margin-bottom: 50px;

    .wrapper {
      h1 {
        padding: 0;
      }
    }
  }

    #hero-img {
      border-radius: 35%;
      box-shadow: ${system.shadows.buttonHover};
      height: auto;
      width: 400px;
      position: absolute;
      top: 125px;
      right: 100px;

      @media ${system.breakpoints[2]} {
        display: none;
      }
    }

    .landing {
      margin-left: 2.5rem;

      a {
        color: ${system.color.white};
      }
    }

  .cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;

    .card {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 22.5%;
      background: ${system.color.white};
      padding: ${system.spacing.bigPadding};
      border-radius: ${system.borders.bigRadius};
      box-shadow: 0 16px 48px rgba(32, 41, 50, 0.12);

      h2 {
        font-size: ${system.fontSizing.m};
      }

      p {
        font-size: ${system.fontSizing.sm};
        color: ${system.color.bodytext}
      }

      li {
        list-style-type: disc;
        margin: 10px 0 0 25px;
        
      }

      h2,
      p {
        width: 100%;
        margin: 0;
        padding: 10px;
        line-height: ${system.spacing.lineHeight};
      }
    }
    @media ${system.breakpoints[2]} {
      flex-flow: column nowrap;
      justify-content: center;

      .card {
        width: 80%;
        margin-bottom: 3rem;
        padding: 2rem;
      }
    }
  }

  #features,
  #social-proof,
  #about {
    @media ${system.breakpoints[2]} {
      padding-bottom: 5rem;
    }

    @media ${system.breakpoints[0]} {
      padding-bottom: 2rem;
    }

    .landing {
      margin: 50px 0 0;
      text-align:center;
    }
  }

  #about {
    #headshot {
      border-radius: 35%;
      box-shadow: ${system.shadows.buttonHover};
      height: auto;
      width: 400px;
      position: absolute;
      top: 775px;
      right: 100px;

      @media ${system.breakpoints[2]} {
        display: none;
      }
    }

    .landing{
      margin: 0 0 0 2.5rem;
    }

  }

  #features {
    .card {
      min-height: 600px;
      h2 {
        color: ${system.color.primary};
        font-size: ${system.fontSizing.ml};

        span {
          font-size: ${system.fontSizing.m};
          color: ${system.color.bodytext};
        }
      }

      @media ${system.breakpoints[2]} {
        min-height: 0;
      }
    }
  }

  #social-proof {
    .card {
      background: #333;
      position: relative;
      justify-content: center;
      width: 30%;
      margin-bottom: 50px;
      min-height: 200px;

      .landing {
        margin-left: 0;
      }

      @media ${system.breakpoints[2]} {
        min-height: 0;
        width: 100%;
      }

      .stripe {
        position: absolute;
        top: 0;
        border-radius: ${system.borders.radius} ${system.borders.radius} 0 0;
        height: 20px;
        width: 100%;
        background: linear-gradient(65deg, #FC475A 0, #118AB2 100%);
      }

      h2,
      p {
        color: ${system.color.white};
        padding: 20px 10px 0px;
        span {
          font-family: 'Montserrat', sans-serif;
          font-size: ${system.fontSizing.m};
        }
      }
    }

    .large {
      min-height: 450px;

      @media ${system.breakpoints[2]} {
        min-height: 0;
      }
    }

    .mid {
      min-height: 300px;
      
      @media ${system.breakpoints[2]} {
        min-height: 0;
      }
    }
  }
`