import React, {useState} from 'react'
import styled from '@emotion/styled'
import system from '../../design/theme'
import LinkItem from './LinkItem'

const TopBar = () => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }


  return (
    <Nav>
      <Container logo           
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <LinkItem to='/'><span>{isHovering ? 'Ahh, Much Better.' : 'Unfuck Your Resume'}</span></LinkItem>
      </Container>
      <Container extra>
        <LinkItem to="#features" className="entry">
          Get Started
        </LinkItem>
      </Container>
    </Nav>
  )
}

export default TopBar

const Container = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  width: ${props => (props.extra ? '225px' : null)};

  @media ${system.breakpoints[1]} {
    display: ${props => (props.extra ? 'none' : 'flex')};
  }

  span {
    font-size: ${system.fontSizing.l};
    font-family: 'Rye', serif;
    color: ${system.color.white};
    :hover { 
      font-family: 'Lato', serif;
    }
    @media ${system.breakpoints[0]} {
      font-size: ${system.fontSizing.m}
    }
  }

  a,
  button {
    font-size: ${system.fontSizing.sm};
    cursor: pointer;
    color: ${system.color.white};
    background: transparent;
    text-decoration: none;
    text-align: center;
    padding: ${props => (props.logo ? null : system.spacing.standardPadding)};
    border: ${props => (props.logo ? null : `1px solid ${system.color.white}`)};
    border-radius: ${system.borders.radius};
    transition: ${system.transition};
    :hover {
      background: ${props => (props.logo ? null : system.color.primary)};
      color: ${props => (props.logo ? null : system.color.white)};
      border: ${props => (props.logo ? null : `1px solid ${system.color.primary}`)};
    }
  }

  p {
    font-size: ${system.fontSizing.s};
    color: ${system.color.white};
    margin: 5px 15px 0;
  }

  #crumb {
    text-transform: capitalize;
  }

  @media ${system.breakpoints[0]} {
    #crumb,
    #logout {
      display: none;
    }

    img {
      height: 2rem;
      width: auto;
    }
  }
`

const Nav = styled.nav`
  position: fixed;
  z-index: 100;
  top: 0;
  background: ${system.color.bodytext};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 11rem;
  height: ${system.spacing.TopBar};

  @media ${system.breakpoints[0]} {
    justify-content: center;
  }
`
